// import { Suspense, useEffect, useState } from 'react';
// import { useLocation, useRoutes, useNavigationType } from 'react-router-dom';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import routes from '../../routes';
// import Loading from '../Loading';

// import "./index.scss";

// export default function Main() {
//   const elements = useRoutes(routes);
//   const location = useLocation();
//   const navigationType = useNavigationType();
//   const [isLoading, setIsLoading] = useState(false);
//   const [showLoading, setShowLoading] = useState(false);

//   const minimumLoadingTime = 700; 

//   useEffect(() => {
//     if (navigationType === 'PUSH') {
//       setIsLoading(true);
//       setShowLoading(true);  

//       const timer = setTimeout(() => {
//         setIsLoading(false);  
//       }, minimumLoadingTime);

//       return () => clearTimeout(timer);
//     }
//   }, [location.key, navigationType]);

//   useEffect(() => {
    
//     if (!isLoading) {
//       const timer = setTimeout(() => {
//         setShowLoading(false);  
//       }, 500);  

//       return () => clearTimeout(timer);
//     }
//   }, [isLoading]);

//   return (
//     <main>
//       <TransitionGroup>
//         {showLoading && (
//           <CSSTransition
//             in={isLoading} 
//             timeout={500}
//             classNames="loading-transition"
//             unmountOnExit 
//             onExited={() => {
//               console.log('Loading exit animation completed');
//             }}
//           >
//             <Loading />
//           </CSSTransition>
//         )}

//         <CSSTransition
//           key={location.key}
//           timeout={500}
//           classNames="page-transition"
//         >
//           <Suspense fallback={null}>
//             {!isLoading && elements}
//           </Suspense>
//         </CSSTransition>
//       </TransitionGroup>
//     </main>
//   );
// }


import { Suspense, useEffect, useState, useRef } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import routes from '../../routes';
import Loading from '../Loading';
import './index.scss';

export default function Main() {
  const elements = useRoutes(routes);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const loadingStartTime = useRef(0);
  const minimumLoadingTime = 700; // 最小加载时间

  useEffect(() => {
    // 路由变化时开始加载
    setIsLoading(true);
    setShowLoading(true);
    loadingStartTime.current = Date.now();
  }, [location.key]);

  const handleLoadingFinish = () => {
    // 计算剩余需要强制展示的时间
    const elapsed = Date.now() - loadingStartTime.current;
    const remaining = Math.max(minimumLoadingTime - elapsed, 0);

    setTimeout(() => {
      setIsLoading(false);
      setShowLoading(false);
    }, remaining);
  };

  return (
    <main>
      <TransitionGroup>
        {/* Loading 组件的淡出动画 */}
        {showLoading && (
          <CSSTransition
            in={isLoading}
            timeout={500}
            classNames="loading-transition"
            unmountOnExit
          >
            <Loading />
          </CSSTransition>
        )}

        {/* 页面内容的淡入动画 */}
        <CSSTransition
          key={location.key}
          timeout={500}
          classNames="page-transition"
        >
          <Suspense
            fallback={
              // 使用隐藏的 fallback 避免布局跳动
              <span style={{ display: 'none' }} />
            }
          >
            {/* 自动检测加载状态的组件 */}
            <LoadingTracker onFinish={handleLoadingFinish} />
            {elements}
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
    </main>
  );
}

// 自动检测加载状态的组件
type Props={
  onFinish:()=>void
}
function LoadingTracker({ onFinish }:Props) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    // 组件挂载后触发加载完成
    onFinish();
  }, [isMounted, onFinish]);

  return null;
}